// src/pages/MyReservationsPage.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';

function MyReservationsPage() {
  const [reservations, setReservations] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem('customerToken');

  useEffect(() => {
    fetchReservations();
    // eslint-disable-next-line
  }, []);

  const fetchReservations = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/reservations/my`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setReservations(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching reservations:', error);
      setErrorMsg('Failed to load your reservations.');
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 mt-20">
      <h2 className="text-3xl font-bold mb-6 text-center">My Reservations</h2>
      {errorMsg && <div className="text-red-600 mb-4 text-center">{errorMsg}</div>}

      {loading ? (
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin text-4xl text-blue-600" />
        </div>
      ) : reservations.length > 0 ? (
        <>
          <div className="flex flex-col md:flex-row md:justify-between mb-4">
            <div className="mb-2 md:mb-0">
              <p className="text-lg font-semibold">
                Total Reservations: <span className="text-blue-600">{reservations.length}</span>
              </p>
            </div>
            {/* Future enhancements: Add filters or export options here */}
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border rounded-lg">
              <thead className="bg-gray-200">
                <tr>
                  <th className="px-4 py-2 border">Reservation ID</th>
                  <th className="px-4 py-2 border">Reference</th> {/* New Column */}
                  <th className="px-4 py-2 border">Date</th>
                  <th className="px-4 py-2 border">Units Reserved</th>
                  <th className="px-4 py-2 border">Status</th>
                </tr>
              </thead>
              <tbody>
                {reservations.map((reservation) => (
                  <tr
                    key={reservation._id}
                    className={`${
                      reservation.status === 'Approved'
                        ? 'bg-green-50'
                        : reservation.status === 'Rejected'
                        ? 'bg-red-50'
                        : 'bg-yellow-50'
                    } hover:bg-gray-100 transition-colors`}
                  >
                    <td className="px-4 py-2 border text-center">{reservation._id}</td>
                    <td className="px-4 py-2 border">
                      {reservation.referenceText || '—'}
                    </td>
                    <td className="px-4 py-2 border">
                      {new Date(reservation.createdAt).toLocaleString()}
                    </td>
                    <td className="px-4 py-2 border">
                      <ul className="list-disc pl-5">
                        {reservation.spaUnitIds.map((unit) => (
                          <li key={unit._id}>
                            {unit.spaModelId.name} - {unit.color} (€{unit.spaModelId.price.toLocaleString()})
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td className="px-4 py-2 border capitalize">
                      {reservation.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <p className="text-center text-gray-600">You have no reservations.</p>
      )}
    </div>
  );
}

export default MyReservationsPage;
