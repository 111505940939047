// src/pages/ModelDetailsPage.js

import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { FaCheckCircle, FaTimesCircle, FaSpinner } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './ModelDetailsPage.css'; // Import the custom CSS

function ModelDetailsPage() {
  const { id } = useParams();
  const [spaModel, setSpaModel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    fetchSpaModel();
    // eslint-disable-next-line
  }, []);

  const fetchSpaModel = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/spa-models/${id}`);
      setSpaModel(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching spa model:', error);
      setErrorMsg('Failed to load spa model details.');
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin text-4xl text-blue-600" aria-label="Loading Spinner" />
      </div>
    );
  }

  if (errorMsg) {
    return (
      <div className="container mx-auto p-8 mt-20 text-center">
        <p className="text-red-600 text-xl">{errorMsg}</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 mt-20">
      {/* Model Title */}
      <h1 className="text-4xl font-bold mb-6 text-center">{spaModel.name}</h1>

      {/* Image Gallery */}
      {spaModel.images && spaModel.images.length > 0 ? (
        <div className="mb-6">
          <Carousel
            showThumbs={true}
            infiniteLoop={true}
            useKeyboardArrows={true}
            autoPlay={true}
            interval={5000}
            stopOnHover={true}
            dynamicHeight={false}
            showStatus={false}
            thumbWidth={80} // Adjusted thumbnail width
            ariaLabel="Spa Model Image Carousel"
            className="custom-carousel rounded-lg shadow-lg"
          >
            {spaModel.images.map((image, index) => (
              <div key={index} className="flex justify-center items-center bg-gray-100">
                <img
                  src={`${process.env.REACT_APP_API_URL}${image}`}
                  alt={`${spaModel.name} - Image ${index + 1}`}
                  className="object-contain h-96 w-full max-w-4xl rounded-lg"
                  loading="lazy"
                />
              </div>
            ))}
          </Carousel>
        </div>
      ) : (
        <div className="w-full h-96 bg-gray-200 flex items-center justify-center rounded-lg mb-6">
          <span className="text-gray-500 text-lg">No Images Available</span>
        </div>
      )}

      {/* Specifications */}
      <div className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">Specifications</h2>
        <div className="bg-white p-6 rounded-lg shadow-md">
          {spaModel.specifications && spaModel.specifications.trim() !== '' ? (
            <ReactQuill
              value={spaModel.specifications}
              readOnly
              theme="bubble"
              className="prose max-w-none"
            />
          ) : (
            <p className="text-gray-500">No specifications available.</p>
          )}
        </div>
      </div>

      {/* Price and Availability */}
      <div className="mb-6 flex flex-col md:flex-row justify-between items-center bg-white p-6 rounded-lg shadow-md">
        <div className="mb-4 md:mb-0">
          <p className="text-xl">
            <strong>Price:</strong> €{spaModel.price ? spaModel.price.toLocaleString() : 'N/A'}
          </p>
        </div>
        <div>
          {spaModel.availabilityStatus ? (
            <span className="flex items-center text-green-600">
              <FaCheckCircle className="mr-2" aria-hidden="true" /> Available
            </span>
          ) : (
            <span className="flex items-center text-red-600">
              <FaTimesCircle className="mr-2" aria-hidden="true" /> Unavailable
            </span>
          )}
        </div>
      </div>

      {/* Description */}
      <div className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">Description</h2>
        <div className="bg-white p-6 rounded-lg shadow-md">
          {spaModel.description && spaModel.description.trim() !== '' ? (
            <ReactQuill
              value={spaModel.description}
              readOnly
              theme="bubble"
              className="prose max-w-none"
            />
          ) : (
            <p className="text-gray-500">No description available.</p>
          )}
        </div>
      </div>

      {/* Reserve Now Button */}
      <div className="text-center">
        {spaModel.availabilityStatus && (
          <Link
            to="/available-stock"
            className="inline-flex items-center bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-full transition-colors"
            aria-label={`Reserve ${spaModel.name}`}
          >
            Reserve This Model
          </Link>
        )}
      </div>
    </div>
  );
}

export default ModelDetailsPage;
