// src/pages/Admin/ManageSpaModelsPage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import {
  FaEdit,
  FaTrash,
  FaSpinner,
  FaTimesCircle,
  FaCheckCircle,
} from 'react-icons/fa';

function ManageSpaModelsPage() {
  const [spaModels, setSpaModels] = useState([]);
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentModel, setCurrentModel] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    collectionId: '',
    colors: '',
    sizes: '',
    specifications: '',
    price: '',
    availabilityStatus: true,
    description: '',
    isFeatured: false,
    images: [],
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchSpaModels();
    fetchCollections();
    // eslint-disable-next-line
  }, []);

  const fetchSpaModels = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/spa-models`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSpaModels(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching spa models:', error);
      setErrorMsg('Failed to load spa models.');
      setLoading(false);
    }
  };

  const fetchCollections = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/collections`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCollections(response.data);
    } catch (error) {
      console.error('Error fetching collections:', error);
      toast.error('Failed to load collections.');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this spa model?')) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/spa-models/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success('Spa model deleted.');
        fetchSpaModels();
      } catch (error) {
        console.error('Error deleting spa model:', error);
        toast.error(
          error.response?.data?.message ||
            'Failed to delete spa model. Ensure it has no associated spa units.'
        );
      }
    }
  };

  const openEditModal = (model) => {
    setCurrentModel(model);
    setFormData({
      name: model.name,
      collectionId: model.collectionId._id,
      colors: model.colors.join(', '),
      sizes: model.sizes.join(', '),
      specifications: model.specifications,
      price: model.price,
      availabilityStatus: model.availabilityStatus,
      description: model.description,
      isFeatured: model.isFeatured,
      images: [], // Images will be handled separately
    });
    setIsModalOpen(true);
  };

  const closeEditModal = () => {
    setIsModalOpen(false);
    setCurrentModel(null);
    setFormData({
      name: '',
      collectionId: '',
      colors: '',
      sizes: '',
      specifications: '',
      price: '',
      availabilityStatus: true,
      description: '',
      isFeatured: false,
      images: [],
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else if (type === 'file') {
      setFormData((prevData) => ({
        ...prevData,
        images: files,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Validate required fields
    if (
      !formData.name ||
      !formData.collectionId ||
      !formData.price ||
      !formData.specifications ||
      !formData.description
    ) {
      toast.error('Please fill in all required fields.');
      setIsSubmitting(false);
      return;
    }

    // Prepare form data for multipart/form-data if images are included
    const submitData = new FormData();
    submitData.append('name', formData.name);
    submitData.append('collectionId', formData.collectionId);
    submitData.append('colors', formData.colors);
    submitData.append('sizes', formData.sizes);
    submitData.append('specifications', formData.specifications);
    submitData.append('price', formData.price);
    submitData.append('availabilityStatus', formData.availabilityStatus);
    submitData.append('description', formData.description);
    submitData.append('isFeatured', formData.isFeatured);
    if (formData.images.length > 0) {
      for (let i = 0; i < formData.images.length; i++) {
        submitData.append('images', formData.images[i]);
      }
    }

    try {
      if (currentModel) {
        // Update existing spa model
        await axios.put(
          `${process.env.REACT_APP_API_URL}/api/spa-models/${currentModel._id}`,
          submitData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success('Spa model updated successfully.');
      } else {
        // Create new spa model
        await axios.post(`${process.env.REACT_APP_API_URL}/api/spa-models`, submitData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success('Spa model created successfully.');
      }
      fetchSpaModels();
      closeEditModal();
    } catch (error) {
      console.error('Error submitting spa model:', error);
      toast.error(
        error.response?.data?.message || 'Failed to submit spa model. Please check your inputs.'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 mt-20">
      <h2 className="text-3xl font-bold mb-6 text-center">Manage Spa Models</h2>

      {/* Error Message */}
      {errorMsg && (
        <div className="bg-red-100 text-red-700 p-4 mb-4 rounded text-center">
          {errorMsg}
        </div>
      )}

      {/* Add New Spa Model Button */}
      <div className="mb-6 text-center">
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-green-600 text-white px-6 py-3 rounded-lg hover:bg-green-700 transition-colors flex items-center justify-center"
        >
          <FaEdit className="mr-2" />
          Add New Spa Model
        </button>
      </div>

      {/* Spa Models Table */}
      {loading ? (
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin text-4xl text-blue-600" aria-label="Loading Spinner" />
        </div>
      ) : spaModels.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border rounded-lg">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-2 border">Image</th>
                <th className="px-4 py-2 border">Name</th>
                <th className="px-4 py-2 border">Collection</th>
                <th className="px-4 py-2 border">Colors</th>
                <th className="px-4 py-2 border">Sizes</th>
                <th className="px-4 py-2 border">Price (€)</th>
                <th className="px-4 py-2 border">Availability</th>
                <th className="px-4 py-2 border">Featured</th>
                <th className="px-4 py-2 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {spaModels.map((model) => (
                <tr key={model._id} className="hover:bg-gray-100 transition-colors">
                  <td className="px-4 py-2 border">
                    {model.images && model.images.length > 0 ? (
                      <img
                        src={`${process.env.REACT_APP_API_URL}${model.images[0]}`}
                        alt={model.name}
                        className="w-16 h-16 object-cover rounded"
                      />
                    ) : (
                      <div className="w-16 h-16 bg-gray-200 flex items-center justify-center rounded">
                        <span className="text-gray-500 text-sm">No Image</span>
                      </div>
                    )}
                  </td>
                  <td className="px-4 py-2 border">{model.name}</td>
                  <td className="px-4 py-2 border">{model.collectionId.name}</td>
                  <td className="px-4 py-2 border">{model.colors.join(', ')}</td>
                  <td className="px-4 py-2 border">{model.sizes.join(', ')}</td>
                  <td className="px-4 py-2 border">€{model.price.toLocaleString()}</td>
                  <td className="px-4 py-2 border">
                    {model.availabilityStatus ? (
                      <span className="flex items-center text-green-600">
                        <FaCheckCircle className="mr-1" aria-hidden="true" /> Available
                      </span>
                    ) : (
                      <span className="flex items-center text-red-600">
                        <FaTimesCircle className="mr-1" aria-hidden="true" /> Unavailable
                      </span>
                    )}
                  </td>
                  <td className="px-4 py-2 border">
                    {model.isFeatured ? (
                      <span className="flex items-center text-purple-600">
                        <FaCheckCircle className="mr-1" aria-hidden="true" /> Yes
                      </span>
                    ) : (
                      <span className="flex items-center text-gray-600">
                        <FaTimesCircle className="mr-1" aria-hidden="true" /> No
                      </span>
                    )}
                  </td>
                  <td className="px-4 py-2 border">
                    <button
                      onClick={() => openEditModal(model)}
                      className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700 transition-colors flex items-center mr-2 mb-2 md:mb-0"
                    >
                      <FaEdit className="mr-1" aria-hidden="true" /> Edit
                    </button>
                    <button
                      onClick={() => handleDelete(model._id)}
                      className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700 transition-colors flex items-center"
                    >
                      <FaTrash className="mr-1" aria-hidden="true" /> Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center text-gray-600">No spa models found. Please add some.</p>
      )}

      {/* Modal for Adding/Editing Spa Model */}
      <Modal open={isModalOpen} onClose={closeEditModal} center>
        <h2 className="text-2xl font-bold mb-4">
          {currentModel ? 'Edit Spa Model' : 'Add New Spa Model'}
        </h2>
        <form onSubmit={handleFormSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Name */}
            <div>
              <label htmlFor="name" className="block text-sm font-semibold text-gray-700 mb-1">
                Name:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>

            {/* Collection */}
            <div>
              <label htmlFor="collectionId" className="block text-sm font-semibold text-gray-700 mb-1">
                Collection:
              </label>
              <select
                id="collectionId"
                name="collectionId"
                value={formData.collectionId}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              >
                <option value="">Select Collection</option>
                {collections.map((collection) => (
                  <option key={collection._id} value={collection._id}>
                    {collection.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Colors */}
            <div className="md:col-span-2">
              <label htmlFor="colors" className="block text-sm font-semibold text-gray-700 mb-1">
                Colors (comma separated):
              </label>
              <input
                type="text"
                id="colors"
                name="colors"
                value={formData.colors}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="e.g., Red, Blue, Green"
              />
            </div>

            {/* Sizes */}
            <div className="md:col-span-2">
              <label htmlFor="sizes" className="block text-sm font-semibold text-gray-700 mb-1">
                Sizes (comma separated):
              </label>
              <input
                type="text"
                id="sizes"
                name="sizes"
                value={formData.sizes}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="e.g., Small, Medium, Large"
              />
            </div>

            {/* Specifications */}
            <div className="md:col-span-2">
              <label htmlFor="specifications" className="block text-sm font-semibold text-gray-700 mb-1">
                Specifications:
              </label>
              <ReactQuill
                theme="snow"
                value={formData.specifications}
                onChange={(value) =>
                  setFormData((prevData) => ({ ...prevData, specifications: value }))
                }
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline'],
                    ['link', 'image'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['clean'],
                  ],
                }}
                formats={[
                  'header',
                  'bold',
                  'italic',
                  'underline',
                  'link',
                  'image',
                  'list',
                  'bullet',
                ]}
                className="h-32 bg-white rounded-md"
                required
              />
            </div>

            {/* Price */}
            <div>
              <br></br>
              <label htmlFor="price" className="block text-sm font-semibold text-gray-700 mb-1">
                Price (€):
              </label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                  €
                </span>
                <input
                  type="number"
                  id="price"
                  name="price"
                  value={formData.price}
                  onChange={handleInputChange}
                  className="w-full pl-8 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                  min="0"
                  step="0.01"
                />
              </div>
            </div>

            <br></br>
            
            {/* Availability Status */}
            <div className="flex items-center">
              <label htmlFor="availabilityStatus" className="flex items-center cursor-pointer">
                <div className="relative">
                  <input
                    type="checkbox"
                    id="availabilityStatus"
                    name="availabilityStatus"
                    checked={formData.availabilityStatus}
                    onChange={handleInputChange}
                    className="sr-only"
                  />
                  <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                  <div
                    className={`dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition ${
                      formData.availabilityStatus ? 'transform translate-x-full bg-green-500' : ''
                    }`}
                  ></div>
                </div>
                <span className="ml-3 text-sm font-semibold text-gray-700">
                  Available
                </span>
              </label>
            </div>

            {/* Featured */}
            <div className="flex items-center">
              <label htmlFor="isFeatured" className="flex items-center cursor-pointer">
                <div className="relative">
                  <input
                    type="checkbox"
                    id="isFeatured"
                    name="isFeatured"
                    checked={formData.isFeatured}
                    onChange={handleInputChange}
                    className="sr-only"
                  />
                  <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                  <div
                    className={`dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition ${
                      formData.isFeatured ? 'transform translate-x-full bg-purple-500' : ''
                    }`}
                  ></div>
                </div>
                <span className="ml-3 text-sm font-semibold text-gray-700">
                  Featured
                </span>
              </label>
            </div>

            {/* Description */}
            <div className="md:col-span-2">
              <label htmlFor="description" className="block text-sm font-semibold text-gray-700 mb-1">
                Description:
              </label>
              <ReactQuill
                theme="snow"
                value={formData.description}
                onChange={(value) =>
                  setFormData((prevData) => ({ ...prevData, description: value }))
                }
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline'],
                    ['link', 'image'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['clean'],
                  ],
                }}
                formats={[
                  'header',
                  'bold',
                  'italic',
                  'underline',
                  'link',
                  'image',
                  'list',
                  'bullet',
                ]}
                className="h-32 bg-white rounded-md"
                required
              />
            </div>
            <br></br>

            {/* Images */}
            <div className="md:col-span-2">
              <label htmlFor="images" className="block text-sm font-semibold text-gray-700 mb-1">
                Upload Images:
              </label>
              <input
                type="file"
                id="images"
                name="images"
                accept="image/*"
                multiple
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <small className="text-gray-600">You can upload multiple images.</small>
            </div>
          </div>

          {/* Form Actions */}
          <div className="flex justify-end mt-6 space-x-4">
            <button
              type="button"
              onClick={closeEditModal}
              className="bg-gray-600 text-white px-5 py-2 rounded-md hover:bg-gray-700 transition-colors flex items-center"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-600 text-white px-5 py-2 rounded-md hover:bg-blue-700 transition-colors flex items-center"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <FaSpinner className="animate-spin mr-2" aria-hidden="true" />
                  {currentModel ? 'Updating...' : 'Creating...'}
                </>
              ) : (
                <>
                  {currentModel ? 'Update Spa Model' : 'Create Spa Model'}
                  <FaCheckCircle className="ml-2" aria-hidden="true" />
                </>
              )}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default ManageSpaModelsPage;
