// src/components/Footer.js

import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'; // Ensure logo.svg is placed in src/assets/
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from 'react-icons/fa';

function Footer() {
  return (
    <footer className="bg-gray-800 text-gray-300 py-12 mt-20">
      <div className="container mx-auto px-4">
        <div className="flex flex-col lg:flex-row justify-between">
          {/* Logo and Description */}
          <div className="mb-8 lg:mb-0 lg:w-1/4">
            <Link to="/" className="flex items-center mb-4">
              <img src={logo} alt="2Stock Spa" className="h-10 mr-3" />
            </Link>
            <p className="text-sm">
              2Stock Spa offers a premium selection of spa models designed to provide ultimate relaxation and luxury. Explore our collection to find the perfect spa for your needs.
            </p>
          </div>

          {/* Quick Links */}
          <div className="mb-8 lg:mb-0 lg:w-1/4">
            <h3 className="text-lg font-semibold text-white mb-4">Quick Links</h3>
            <ul>
              <li className="mb-2">
                <Link to="/spa-models" className="hover:text-white transition-colors">
                  Spa Models
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/available-stock" className="hover:text-white transition-colors">
                  Available Stock
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/my-reservations" className="hover:text-white transition-colors">
                  My Reservations
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/contact" className="hover:text-white transition-colors">
                  Contact Us
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/about" className="hover:text-white transition-colors">
                  About Us
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Information */}
          <div className="mb-8 lg:mb-0 lg:w-1/4">
            <h3 className="text-lg font-semibold text-white mb-4">Contact Us</h3>
            <p className="text-sm mb-2">
              <strong>Email:</strong> <a href="mailto:contact@rocaspa.fr" className="hover:text-white">contact@rocaspa.fr</a>
            </p>
            <p className="text-sm mb-2">
              <strong>Phone:</strong> <a href="tel:+33652016373" className="hover:text-white">+336 52 01 63 73</a>
            </p>
            <p className="text-sm">
              <strong>Head office :</strong>  1527 Avenue de petite synthe 59640 Dunkerque
            </p>
            <p className="text-sm">
              <strong>Main Warehouse of stock :</strong> 15 rue du guindal bâtiment 1.16 and 1.17 59630 Bourbourg
            </p>
            <p className="text-sm">
              <strong>2nd Warehouse stock :</strong> 3 rue des caroubiers 66600 Rivesaltes
            </p>
          </div>

          {/* Social Media */}
          <div className="lg:w-1/4">
            <h3 className="text-lg font-semibold text-white mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              <a
                href="https://www.facebook.com/profile.php?id=61553383875495"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-white transition-colors"
                aria-label="Facebook"
              >
                <FaFacebookF size={24} /> {/* Increased icon size from 20 to 24 */}
              </a>
            </div>
          </div>
        </div>

        {/* Bottom Footer */}
        <div className="mt-8 border-t border-gray-700 pt-4">
          <p className="text-center text-sm">
            &copy; {new Date().getFullYear()} 2Stock Spa. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
