// src/components/SpaModelsCarousel.js

import React from 'react';
import Slider from 'react-slick';
import SpaModelCard from './SpaModelCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function SpaModelsCarousel({ spaModels }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: spaModels.length >= 3 ? 3 : spaModels.length,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024, // Tablets and below
        settings: {
          slidesToShow: spaModels.length >= 2 ? 2 : spaModels.length,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600, // Mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="mt-8">
      <Slider {...settings}>
        {spaModels.map((model) => (
          <div key={model._id} className="px-2">
            <SpaModelCard model={model} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default SpaModelsCarousel;
