// src/pages/HomePage.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SpaModelsCarousel from '../components/SpaModelsCarousel';
import heroImage from '../assets/hero.jpeg';
import { FaArrowRight } from 'react-icons/fa';

function HomePage() {
  const [featuredModels, setFeaturedModels] = useState([]);

  useEffect(() => {
    const fetchFeaturedModels = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/spa-models`);
        const featured = response.data.filter((model) => model.isFeatured);
        setFeaturedModels(featured);
      } catch (error) {
        console.error('Error fetching featured models:', error);
      }
    };
    fetchFeaturedModels();
  }, []);

  return (
    <div className="mt-16">
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center h-screen flex items-center justify-center"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-black opacity-50"></div>
        {/* Content */}
        <div className="relative text-center text-white px-4">
          <h1 className="text-4xl md:text-6xl font-bold mb-4 animate-fade-in">
            Welcome to 2Stock Spa
          </h1>
          <p className="text-lg md:text-2xl mb-8 animate-fade-in-delay">
            Experience Luxury and Relaxation
          </p>
          <a
            href="/spa-models"
            className="inline-flex items-center bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-full transition-colors animate-fade-in-delay-2"
          >
            Explore Our Spa Models
            <FaArrowRight className="ml-2" />
          </a>
        </div>
      </div>

      {/* Featured Spa Models */}
      <div className="container mx-auto px-4 py-12">
        <h2 className="text-3xl font-bold mb-6 text-center">Featured Spa Models</h2>
        {featuredModels.length > 0 ? (
          <SpaModelsCarousel spaModels={featuredModels} />
        ) : (
          <p className="text-center text-gray-600">No featured spa models at the moment.</p>
        )}
      </div>
    </div>
  );
}

export default HomePage;
