// src/pages/Admin/ManageStockPage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FaTrash, FaEdit, FaSpinner, FaTimesCircle, FaCheckCircle } from 'react-icons/fa';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

function ManageStockPage() {
  const [spaUnits, setSpaUnits] = useState([]);
  const [spaModels, setSpaModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentUnit, setCurrentUnit] = useState(null);

  const [formData, setFormData] = useState({
    spaModelId: '',
    color: '',
    quantity: 1, // Remains in Add Form
    availabilityDate: '',
    isReserved: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchSpaUnits();
    fetchSpaModels();
    // eslint-disable-next-line
  }, []);

  const fetchSpaUnits = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/spa-units/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSpaUnits(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching spa units:', error);
      setErrorMsg('Failed to load spa units.');
      setLoading(false);
    }
  };

  const fetchSpaModels = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/spa-models`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSpaModels(response.data);
    } catch (error) {
      console.error('Error fetching spa models:', error);
      toast.error('Failed to load spa models.');
    }
  };

  const handleAddUnits = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!formData.spaModelId || !formData.color || !formData.quantity) {
      toast.error('Please fill in all required fields.');
      setIsSubmitting(false);
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/spa-units/add-units`,
        {
          spaModelId: formData.spaModelId,
          color: formData.color,
          quantity: formData.quantity,
          availabilityDate: formData.availabilityDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success('Spa units added successfully.');
      setFormData({
        spaModelId: '',
        color: '',
        quantity: 1,
        availabilityDate: '',
        isReserved: false,
      });
      setIsAddModalOpen(false);
      fetchSpaUnits();
    } catch (error) {
      console.error('Error adding spa units:', error);
      toast.error(error.response?.data?.message || 'Failed to add spa units.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditUnit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!formData.spaModelId || !formData.color) {
      toast.error('Please fill in all required fields.');
      setIsSubmitting(false);
      return;
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/spa-units/${currentUnit._id}`,
        {
          spaModelId: formData.spaModelId,
          color: formData.color,
          availabilityDate: formData.availabilityDate,
          isReserved: formData.isReserved,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success('Spa unit updated successfully.');
      setIsEditModalOpen(false);
      setCurrentUnit(null);
      setFormData({
        spaModelId: '',
        color: '',
        quantity: 1, // Not used in Edit
        availabilityDate: '',
        isReserved: false,
      });
      fetchSpaUnits();
    } catch (error) {
      console.error('Error updating spa unit:', error);
      toast.error(error.response?.data?.message || 'Failed to update spa unit.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this spa unit?')) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/spa-units/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success('Spa unit deleted.');
        fetchSpaUnits();
      } catch (error) {
        console.error('Error deleting spa unit:', error);
        toast.error(error.response?.data?.message || 'Failed to delete spa unit.');
      }
    }
  };

  const openEditModal = (unit) => {
    setCurrentUnit(unit);
    setFormData({
      spaModelId: unit.spaModelId._id,
      color: unit.color,
      quantity: 1, // Not used in Edit
      availabilityDate: unit.availabilityDate ? unit.availabilityDate.split('T')[0] : '',
      isReserved: unit.isReserved,
    });
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setCurrentUnit(null);
    setFormData({
      spaModelId: '',
      color: '',
      quantity: 1,
      availabilityDate: '',
      isReserved: false,
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 mt-20">
      <h2 className="text-3xl font-bold mb-6 text-center">Manage Stock</h2>

      {/* Error Message */}
      {errorMsg && (
        <div className="bg-red-100 text-red-700 p-4 mb-4 rounded text-center">
          {errorMsg}
        </div>
      )}

      {/* Add Spa Units Button */}
      <div className="mb-6 text-center">
        <button
          onClick={() => setIsAddModalOpen(true)}
          className="bg-green-600 text-white px-6 py-3 rounded-lg hover:bg-green-700 transition-colors flex items-center justify-center"
        >
          <FaEdit className="mr-2" aria-hidden="true" />
          Add New Spa Units
        </button>
      </div>

      {/* Spa Units Table */}
      {loading ? (
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin text-4xl text-blue-600" aria-label="Loading Spinner" />
        </div>
      ) : spaUnits.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border rounded-lg">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-2 border">Model</th>
                <th className="px-4 py-2 border">Color</th>
                {/* Removed Quantity Column */}
                <th className="px-4 py-2 border">Availability Date</th>
                <th className="px-4 py-2 border">Reserved</th>
                <th className="px-4 py-2 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {spaUnits.map((unit) => (
                <tr key={unit._id} className="hover:bg-gray-100 transition-colors">
                  <td className="px-4 py-2 border text-center">{unit.spaModelId.name}</td>
                  <td className="px-4 py-2 border capitalize">{unit.color}</td>
                  {/* Removed Quantity Cell */}
                  <td className="px-4 py-2 border text-center">
                    {unit.availabilityDate
                      ? new Date(unit.availabilityDate).toLocaleDateString()
                      : 'N/A'}
                  </td>
                  <td className="px-4 py-2 border text-center">
                    {unit.isReserved ? (
                      <span className="flex items-center justify-center text-red-600">
                        <FaTimesCircle className="mr-1" aria-hidden="true" /> Yes
                      </span>
                    ) : (
                      <span className="flex items-center justify-center text-green-600">
                        <FaCheckCircle className="mr-1" aria-hidden="true" /> No
                      </span>
                    )}
                  </td>
                  <td className="px-4 py-2 border flex justify-center space-x-2">
                    <button
                      onClick={() => openEditModal(unit)}
                      className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700 transition-colors flex items-center"
                      aria-label={`Edit spa unit ${unit.spaModelId.name} - ${unit.color}`}
                    >
                      <FaEdit className="mr-1" aria-hidden="true" /> Edit
                    </button>
                    <button
                      onClick={() => handleDelete(unit._id)}
                      className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700 transition-colors flex items-center"
                      aria-label={`Delete spa unit ${unit.spaModelId.name} - ${unit.color}`}
                    >
                      <FaTrash className="mr-1" aria-hidden="true" /> Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center text-gray-600">No spa units found. Please add some.</p>
      )}

      {/* Modal for Adding Spa Units */}
      <Modal open={isAddModalOpen} onClose={() => setIsAddModalOpen(false)} center>
        <h2 className="text-2xl font-bold mb-4">Add New Spa Units</h2>
        <form onSubmit={handleAddUnits}>
          <div className="grid grid-cols-1 gap-4">
            {/* Spa Model */}
            <div>
              <label htmlFor="spaModelId" className="block text-sm font-medium text-gray-700 mb-1">
                Spa Model<span className="text-red-500">*</span>:
              </label>
              <select
                id="spaModelId"
                name="spaModelId"
                value={formData.spaModelId}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              >
                <option value="">Select Spa Model</option>
                {spaModels.map((model) => (
                  <option key={model._id} value={model._id}>
                    {model.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Color */}
            <div>
              <label htmlFor="color" className="block text-sm font-medium text-gray-700 mb-1">
                Color<span className="text-red-500">*</span>:
              </label>
              <input
                type="text"
                id="color"
                name="color"
                value={formData.color}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="e.g., Red, Blue"
                required
              />
            </div>

            {/* Quantity */}
            <div>
              <label htmlFor="quantity" className="block text-sm font-medium text-gray-700 mb-1">
                Quantity<span className="text-red-500">*</span>:
              </label>
              <input
                type="number"
                id="quantity"
                name="quantity"
                value={formData.quantity}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                min="1"
                required
              />
            </div>

            {/* Availability Date */}
            <div>
              <label htmlFor="availabilityDate" className="block text-sm font-medium text-gray-700 mb-1">
                Availability Date:
              </label>
              <input
                type="date"
                id="availabilityDate"
                name="availabilityDate"
                value={formData.availabilityDate}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          {/* Submit Button */}
          <div className="flex justify-end mt-6">
            <button
              type="submit"
              className="bg-green-600 text-white px-6 py-2 rounded-md hover:bg-green-700 transition-colors flex items-center"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <FaSpinner className="animate-spin mr-2" aria-hidden="true" />
                  Adding...
                </>
              ) : (
                <>
                  Add Units
                  <FaCheckCircle className="ml-2" aria-hidden="true" />
                </>
              )}
            </button>
          </div>
        </form>
      </Modal>

      {/* Modal for Editing Spa Units */}
      <Modal open={isEditModalOpen} onClose={closeEditModal} center>
        <h2 className="text-2xl font-bold mb-4">Edit Spa Unit</h2>
        <form onSubmit={handleEditUnit}>
          <div className="grid grid-cols-1 gap-4">
            {/* Spa Model */}
            <div>
              <label htmlFor="editSpaModelId" className="block text-sm font-medium text-gray-700 mb-1">
                Spa Model<span className="text-red-500">*</span>:
              </label>
              <select
                id="editSpaModelId"
                name="spaModelId"
                value={formData.spaModelId}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              >
                <option value="">Select Spa Model</option>
                {spaModels.map((model) => (
                  <option key={model._id} value={model._id}>
                    {model.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Color */}
            <div>
              <label htmlFor="editColor" className="block text-sm font-medium text-gray-700 mb-1">
                Color<span className="text-red-500">*</span>:
              </label>
              <input
                type="text"
                id="editColor"
                name="color"
                value={formData.color}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="e.g., Red, Blue"
                required
              />
            </div>

            {/* Availability Date */}
            <div>
              <label htmlFor="editAvailabilityDate" className="block text-sm font-medium text-gray-700 mb-1">
                Availability Date:
              </label>
              <input
                type="date"
                id="editAvailabilityDate"
                name="availabilityDate"
                value={formData.availabilityDate}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Reserved Status */}
            <div className="flex items-center">
              <input
                type="checkbox"
                id="editIsReserved"
                name="isReserved"
                checked={formData.isReserved}
                onChange={handleInputChange}
                className="mr-2 h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              />
              <label htmlFor="editIsReserved" className="text-sm font-medium text-gray-700">
                Reserved
              </label>
            </div>
          </div>

          {/* Submit Button */}
          <div className="flex justify-end mt-6">
            <button
              type="submit"
              className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition-colors flex items-center"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <FaSpinner className="animate-spin mr-2" aria-hidden="true" />
                  Updating...
                </>
              ) : (
                <>
                  Update Unit
                  <FaCheckCircle className="ml-2" aria-hidden="true" />
                </>
              )}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default ManageStockPage;
