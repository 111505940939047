// src/pages/AboutUsPage.js

import React from 'react';

import aboutImage from '../assets/about.jpeg';

function AboutUsPage() {
  return (
    <div className="container mx-auto px-4 py-16 mt-20">
      <h1 className="text-4xl font-bold mb-8 text-center">About Us</h1>
      
      <div className="flex flex-col lg:flex-row items-start lg:space-x-12">
        {/* Image Section */}
        <div className="lg:w-1/2 mb-8 lg:mb-0">
          <img
            src= {aboutImage}
            alt="About 2Stock Spa"
            className="w-full h-auto rounded-lg shadow-md"
          />
        </div>

        {/* Content Section */}
        <div className="lg:w-1/2">
          <p className="mb-4 text-gray-700">
            Welcome to <span className="font-semibold text-gray-900">2Stock Spa</span>, your premier destination for luxurious spa models designed to elevate your relaxation experience. Founded, our mission has always been to provide top-quality spa units that combine elegance, functionality, and comfort.
          </p>
          <p className="mb-4 text-gray-700">
            At 2Stock Spa, we believe that everyone deserves a personal sanctuary where they can unwind and rejuvenate. Our extensive collection features a diverse range of spa models, each meticulously crafted to meet the highest standards of quality and design.
          </p>
          <p className="mb-4 text-gray-700">
            Our vision is to become the leading provider of spa solutions, renowned for our commitment to excellence and customer satisfaction. Whether you're looking to enhance your home, hotel, or wellness center, 2Stock Spa has the perfect solution tailored to your needs.
          </p>
          <p className="text-gray-700">
            Thank you for choosing 2Stock Spa. We look forward to helping you create your ideal relaxation space.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutUsPage;
