// src/components/Navbar.js

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/logo.png'; // Ensure logo.png is placed in src/assets/
import { FaBars, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem('token'); // Admin token
  const customerToken = localStorage.getItem('customerToken'); // Customer token
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Close menu when route changes
    setIsOpen(false);
  }, [location]);

  const handleLogout = () => {
    if (token) {
      localStorage.removeItem('token');
    }
    if (customerToken) {
      localStorage.removeItem('customerToken');
    }
    toast.success('Logged out successfully.');
    navigate('/');
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Function to determine if a link is active
  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <nav className="bg-gray-800 text-white fixed w-full z-50 top-0 shadow">
      <div className="container mx-auto px-4 lg:px-8">
        <div className="flex items-center justify-between h-20">
          {/* Logo */}
          <div className="flex-shrink-0">
            <Link to="/" className="flex items-center">
              <img src={logo} alt="2Stock Spa" className="h-10 mr-3" />
            </Link>
          </div>

          {/* Hamburger Menu (Mobile) */}
          <div className="flex lg:hidden">
            <button
              onClick={toggleMenu}
              type="button"
              className="text-gray-300 hover:text-white focus:outline-none focus:text-white"
              aria-controls="mobile-menu"
              aria-expanded={isOpen}
              aria-label="Toggle navigation menu"
            >
              {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>

          {/* Navigation Links (Desktop) */}
          <div className="hidden lg:flex lg:items-center lg:space-x-6">
            <Link
              to="/spa-models"
              className={`block py-2 px-4 hover:bg-gray-700 rounded transition-colors ${
                isActive('/spa-models') ? 'bg-gray-700' : ''
              }`}
            >
              Spa Models
            </Link>
            <Link
              to="/available-stock"
              className={`block py-2 px-4 hover:bg-gray-700 rounded transition-colors ${
                isActive('/available-stock') ? 'bg-gray-700' : ''
              }`}
            >
              Available Stock
            </Link>
            <Link
              to="/about"
              className={`block py-2 px-4 hover:bg-gray-700 rounded transition-colors ${
                isActive('/about') ? 'bg-gray-700' : ''
              }`}
            >
              About Us
            </Link>
            <Link
              to="/contact"
              className={`block py-2 px-4 hover:bg-gray-700 rounded transition-colors ${
                isActive('/contact') ? 'bg-gray-700' : ''
              }`}
            >
              Contact Us
            </Link>

            {/* Conditional Links for Admin */}
            {token && (
              <>
                <Link
                  to="/admin"
                  className={`block py-2 px-4 hover:bg-gray-700 rounded transition-colors ${
                    isActive('/admin') ? 'bg-gray-700' : ''
                  }`}
                >
                  Admin Dashboard
                </Link>
                <button
                  onClick={handleLogout}
                  className="block py-2 px-4 hover:bg-gray-700 rounded transition-colors"
                >
                  Logout
                </button>
              </>
            )}

            {/* Conditional Links for Customer */}
            {customerToken && (
              <>
                <Link
                  to="/my-reservations"
                  className={`block py-2 px-4 hover:bg-gray-700 rounded transition-colors ${
                    isActive('/my-reservations') ? 'bg-gray-700' : ''
                  }`}
                >
                  My Reservations
                </Link>
                <button
                  onClick={handleLogout}
                  className="block py-2 px-4 hover:bg-gray-700 rounded transition-colors"
                >
                  Logout
                </button>
              </>
            )}

            {/* Links for Unauthenticated Users */}
            {!token && !customerToken && (
              <>
                <Link
                  to="/login"
                  className={`block py-2 px-4 hover:bg-gray-700 rounded transition-colors ${
                    isActive('/login') ? 'bg-gray-700' : ''
                  }`}
                >
                  Login
                </Link>
                <Link
                  to="/register"
                  className={`block py-2 px-4 hover:bg-gray-700 rounded transition-colors ${
                    isActive('/register') ? 'bg-gray-700' : ''
                  }`}
                >
                  Register
                </Link>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <>
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={toggleMenu}
          ></div>
          {/* Menu */}
          <div className="absolute top-20 left-0 w-full bg-gray-800 z-50">
            <div className="px-4 py-4 space-y-2">
              <Link
                to="/spa-models"
                onClick={toggleMenu}
                className={`block py-2 px-4 hover:bg-gray-700 rounded transition-colors ${
                  isActive('/spa-models') ? 'bg-gray-700' : ''
                }`}
              >
                Spa Models
              </Link>
              <Link
                to="/available-stock"
                onClick={toggleMenu}
                className={`block py-2 px-4 hover:bg-gray-700 rounded transition-colors ${
                  isActive('/available-stock') ? 'bg-gray-700' : ''
                }`}
              >
                Available Stock
              </Link>
              <Link
                to="/about"
                onClick={toggleMenu}
                className={`block py-2 px-4 hover:bg-gray-700 rounded transition-colors ${
                  isActive('/about') ? 'bg-gray-700' : ''
                }`}
              >
                About Us
              </Link>
              <Link
                to="/contact"
                onClick={toggleMenu}
                className={`block py-2 px-4 hover:bg-gray-700 rounded transition-colors ${
                  isActive('/contact') ? 'bg-gray-700' : ''
                }`}
              >
                Contact Us
              </Link>

              {/* Conditional Links for Admin */}
              {token && (
                <>
                  <Link
                    to="/admin"
                    onClick={toggleMenu}
                    className={`block py-2 px-4 hover:bg-gray-700 rounded transition-colors ${
                      isActive('/admin') ? 'bg-gray-700' : ''
                    }`}
                  >
                    Admin Dashboard
                  </Link>
                  <button
                    onClick={() => {
                      handleLogout();
                      toggleMenu();
                    }}
                    className="block w-full text-left py-2 px-4 hover:bg-gray-700 rounded transition-colors"
                  >
                    Logout
                  </button>
                </>
              )}

              {/* Conditional Links for Customer */}
              {customerToken && (
                <>
                  <Link
                    to="/my-reservations"
                    onClick={toggleMenu}
                    className={`block py-2 px-4 hover:bg-gray-700 rounded transition-colors ${
                      isActive('/my-reservations') ? 'bg-gray-700' : ''
                    }`}
                  >
                    My Reservations
                  </Link>
                  <button
                    onClick={() => {
                      handleLogout();
                      toggleMenu();
                    }}
                    className="block w-full text-left py-2 px-4 hover:bg-gray-700 rounded transition-colors"
                  >
                    Logout
                  </button>
                </>
              )}

              {/* Links for Unauthenticated Users */}
              {!token && !customerToken && (
                <>
                  <Link
                    to="/login"
                    onClick={toggleMenu}
                    className={`block py-2 px-4 hover:bg-gray-700 rounded transition-colors ${
                      isActive('/login') ? 'bg-gray-700' : ''
                    }`}
                  >
                    Login
                  </Link>
                  <Link
                    to="/register"
                    onClick={toggleMenu}
                    className={`block py-2 px-4 hover:bg-gray-700 rounded transition-colors ${
                      isActive('/register') ? 'bg-gray-700' : ''
                    }`}
                  >
                    Register
                  </Link>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </nav>
  );
}

export default Navbar;
