// src/pages/AvailableStock.js 

import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FaSpinner, FaCheck, FaTimes } from 'react-icons/fa';
import { debounce } from 'lodash';

function AvailableStock() {
  const [units, setUnits] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(true);

  const [searchQuery, setSearchQuery] = useState('');
  const [sortOption, setSortOption] = useState({
    field: '',
    order: '',
  });

  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [referenceText, setReferenceText] = useState(''); // Reservation reference
  const [modalError, setModalError] = useState(''); // Error within modal

  const navigate = useNavigate();
  const token = localStorage.getItem('customerToken');

  useEffect(() => {
    fetchAvailableUnits();
    // eslint-disable-next-line
  }, []);

  const fetchAvailableUnits = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/spa-units`);
      setUnits(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching available spa units:', error);
      setErrorMsg('Failed to load available spa units.');
      setLoading(false);
    }
  };

  // Debounced search handler to optimize performance
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        setSearchQuery(value);
      }, 300),
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  const handleSortChange = (e) => {
    const value = e.target.value;
    if (value === '') {
      setSortOption({ field: '', order: '' });
    } else {
      const [field, order] = value.split('_');
      setSortOption({ field, order });
    }
  };

  const handleSelectUnit = (unitId) => {
    setSelectedUnits((prevSelected) => {
      if (prevSelected.includes(unitId)) {
        return prevSelected.filter((id) => id !== unitId);
      } else {
        return [...prevSelected, unitId];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedUnits([]);
    } else {
      setSelectedUnits(filteredAndSortedUnits.map((unit) => unit._id));
    }
    setSelectAll(!selectAll);
  };

  const handleReserve = () => {
    if (selectedUnits.length === 0) {
      toast.error('Please select at least one spa unit to reserve.');
      return;
    }
    setIsModalOpen(true); // Open the modal
  };

  const submitReservation = async () => {
    if (referenceText.trim() === '') {
      setModalError('Please enter a reservation reference.');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/reservations/multiple`,
        { spaUnitIds: selectedUnits, referenceText },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success(response.data.message || 'Reservation created successfully.');
      setSelectedUnits([]);
      setSelectAll(false);
      setReferenceText('');
      setIsModalOpen(false);
      navigate('/reservation-confirmation');
    } catch (error) {
      console.error('Error reserving spa units:', error);
      setModalError(error.response?.data?.message || 'Failed to reserve spa units. Please try again.');
    }
  };

  // Function to determine if a unit is available (today or past)
  const isAvailable = (availabilityDate) => {
    const today = new Date();
    const date = new Date(availabilityDate);
    // Set the time of both dates to midnight to compare only the date parts
    today.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);
    return date <= today;
  };

  // Compute filtered and sorted units
  const filteredAndSortedUnits = useMemo(() => {
    let filtered = units;

    if (searchQuery.trim() !== '') {
      const query = searchQuery.trim().toLowerCase();
      filtered = filtered.filter((unit) => {
        const modelName = unit.spaModelId.name.toLowerCase();
        const color = unit.color.toLowerCase();
        const price = unit.spaModelId.price.toString();
        return (
          modelName.includes(query) ||
          color.includes(query) ||
          price.includes(query)
        );
      });
    }

    if (sortOption.field !== '') {
      filtered = [...filtered].sort((a, b) => {
        let aField, bField;

        switch (sortOption.field) {
          case 'modelName':
            aField = a.spaModelId.name.toLowerCase();
            bField = b.spaModelId.name.toLowerCase();
            break;
          case 'color':
            aField = a.color.toLowerCase();
            bField = b.color.toLowerCase();
            break;
          case 'price':
            aField = a.spaModelId.price;
            bField = b.spaModelId.price;
            break;
          default:
            return 0;
        }

        if (aField < bField) {
          return sortOption.order === 'asc' ? -1 : 1;
        }
        if (aField > bField) {
          return sortOption.order === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return filtered;
  }, [units, searchQuery, sortOption]);

  return (
    <div className="container mx-auto px-4 py-8 mt-20">
      <h2 className="text-3xl font-bold mb-6 text-center">Available Stock</h2>
      {errorMsg && <div className="text-red-600 mb-4 text-center">{errorMsg}</div>}

      {loading ? (
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin text-4xl text-blue-600" aria-label="Loading Spinner" />
        </div>
      ) : units.length > 0 ? (
        <>
          {/* Search and Sort Controls */}
          <div className="flex flex-col md:flex-row md:justify-between mb-4 items-center">
            {/* Search Input */}
            <div className="w-full md:w-1/3 mb-4 md:mb-0">
              <input
                type="text"
                placeholder="Search by Model Name, Color, or Price..."
                onChange={handleSearchChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                aria-label="Search Spa Units"
              />
            </div>

            {/* Sort Dropdown */}
            <div className="w-full md:w-1/4">
              <select
                onChange={handleSortChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                aria-label="Sort Spa Units"
              >
                <option value="">Sort By</option>
                <option value="modelName_asc">Model Name (A-Z)</option>
                <option value="modelName_desc">Model Name (Z-A)</option>
                <option value="color_asc">Color (A-Z)</option>
                <option value="color_desc">Color (Z-A)</option>
                <option value="price_asc">Price (Low to High)</option>
                <option value="price_desc">Price (High to Low)</option>
              </select>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex flex-col md:flex-row md:justify-between mb-4 items-center">
            <div className="mb-2 md:mb-0">
              <p className="text-lg font-semibold">
                Total Available Units: <span className="text-blue-600">{filteredAndSortedUnits.length}</span>
              </p>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={handleReserve}
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
                aria-label="Reserve Selected Units"
              >
                Reserve Selected Units
              </button>
              <button
                onClick={() => {
                  setSelectedUnits([]);
                  setSelectAll(false);
                }}
                className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition-colors"
                aria-label="Clear Selection"
              >
                Clear Selection
              </button>
            </div>
          </div>

          {/* Spa Units Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border rounded-lg">
              <thead className="bg-gray-200">
                <tr>
                  <th className="px-4 py-2 border text-center">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                      className="h-4 w-4 text-blue-600"
                      aria-label="Select All Units"
                    />
                  </th>
                  <th className="px-4 py-2 border">Image</th>
                  <th className="px-4 py-2 border">Model Name</th>
                  <th className="px-4 py-2 border">Color</th>
                  <th className="px-4 py-2 border text-right">Price (€)</th>
                  <th className="px-4 py-2 border">Availability</th>
                </tr>
              </thead>
              <tbody>
                {filteredAndSortedUnits.map((unit) => (
                  <tr
                    key={unit._id}
                    className={`${
                      selectedUnits.includes(unit._id) ? 'bg-blue-50' : ''
                    } hover:bg-gray-100 transition-colors`}
                  >
                    <td className="px-4 py-2 border text-center">
                      <input
                        type="checkbox"
                        checked={selectedUnits.includes(unit._id)}
                        onChange={() => handleSelectUnit(unit._id)}
                        className="h-4 w-4 text-blue-600"
                        aria-label={`Select Unit ${unit.spaModelId.name} - ${unit.color}`}
                      />
                    </td>
                    <td className="px-4 py-2 border">
                      {unit.spaModelId.images && unit.spaModelId.images.length > 0 ? (
                        <img
                          src={`${process.env.REACT_APP_API_URL}${unit.spaModelId.images[0]}`}
                          alt={`${unit.spaModelId.name} - Image`}
                          className="w-32 h-32 object-contain rounded shadow-md"
                          loading="lazy"
                        />
                      ) : (
                        <div className="w-32 h-32 bg-gray-200 flex items-center justify-center rounded">
                          <span className="text-gray-500 text-xs">No Image</span>
                        </div>
                      )}
                    </td>
                    <td className="px-4 py-2 border">{unit.spaModelId.name}</td>
                    <td className="px-4 py-2 border capitalize">{unit.color}</td>
                    <td className="px-4 py-2 border text-right">
                      {unit.spaModelId.price.toLocaleString()}
                    </td>
                    <td className="px-4 py-2 border text-center">
                      {isAvailable(unit.availabilityDate) ? (
                        <span className="text-green-600 font-semibold">Available</span>
                      ) : (
                        new Date(unit.availabilityDate).toLocaleDateString()
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Reservation Modal */}
          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/2 p-6 relative">
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
                  aria-label="Close Modal"
                >
                  <FaTimes size={20} />
                </button>
                <h3 className="text-2xl font-semibold mb-4 text-center">Confirm Reservation</h3>
                <div className="mb-4">
                  <h4 className="text-xl font-semibold mb-2">Selected Spa Units:</h4>
                  <ul className="list-disc pl-5">
                    {units
                      .filter((unit) => selectedUnits.includes(unit._id))
                      .map((unit) => (
                        <li key={unit._id}>
                          {unit.spaModelId.name} - {unit.color} (€{unit.spaModelId.price.toLocaleString()})
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="mb-4">
                  <h4 className="text-xl font-semibold mb-2">Total Price:</h4>
                  <p className="text-lg font-bold">
                    €{units
                      .filter((unit) => selectedUnits.includes(unit._id))
                      .reduce((acc, unit) => acc + unit.spaModelId.price, 0)
                      .toLocaleString()}
                  </p>
                </div>
                <div className="mb-4">
                  <label htmlFor="reference" className="block text-lg font-semibold mb-2">
                    Reservation Reference:
                  </label>
                  <input
                    type="text"
                    id="reference"
                    value={referenceText}
                    onChange={(e) => setReferenceText(e.target.value)}
                    className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter a reference (e.g., Customer's Name)"
                    aria-label="Reservation Reference"
                  />
                  {modalError && <p className="text-red-600 mt-2">{modalError}</p>}
                </div>
                <div className="flex justify-end space-x-2">
                  <button
                    onClick={() => setIsModalOpen(false)}
                    className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700 transition-colors flex items-center"
                    aria-label="Cancel Reservation"
                  >
                    <FaTimes className="mr-1" /> Cancel
                  </button>
                  <button
                    onClick={submitReservation}
                    className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition-colors flex items-center"
                    aria-label="Confirm Reservation"
                  >
                    <FaCheck className="mr-1" /> Confirm
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <p className="text-center text-gray-600">No available spa units at the moment.</p>
      )}
    </div>
  );
}

export default AvailableStock;
