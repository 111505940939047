// src/components/ProtectedRoute.js

import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

function ProtectedRoute({ children, adminOnly }) {
  const [isAuthorized, setIsAuthorized] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem(adminOnly ? 'token' : 'customerToken');
      const isAdmin = !!localStorage.getItem('token');

      if (!token) {
        setIsAuthorized(false);
        return;
      }

      if (adminOnly && !isAdmin) {
        setIsAuthorized(false);
        return;
      }

      if (!adminOnly && !isAdmin) {
        // Check if the customer is approved
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/customer/me`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.data.isApproved) {
            setIsAuthorized(true);
          } else {
            setIsAuthorized(false);
          }
        } catch (error) {
          console.error('Authorization error:', error);
          setIsAuthorized(false);
        }
      } else {
        setIsAuthorized(true);
      }
    };

    checkAuth();
    // eslint-disable-next-line
  }, []);

  if (isAuthorized === null) {
    return null; // Or a loading spinner
  }

  if (!isAuthorized) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

export default ProtectedRoute;
