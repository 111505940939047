// src/pages/Admin/ManageCustomersPage.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FaSpinner, FaCheck, FaTimes, FaTrash } from 'react-icons/fa';

function ManageCustomersPage() {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchCustomers();
    // eslint-disable-next-line
  }, []);

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/customers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCustomers(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching customers:', error);
      setLoading(false);
      toast.error('Failed to load customers.');
    }
  };

  const handleApprove = async (id, isApproved) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/customers/${id}`,
        { isApproved },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(`Customer has been ${isApproved ? 'approved' : 'disapproved'}.`);
      fetchCustomers();
    } catch (error) {
      console.error('Error updating customer:', error);
      toast.error('Failed to update customer.');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this customer?')) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/customers/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success('Customer deleted.');
        fetchCustomers();
      } catch (error) {
        console.error('Error deleting customer:', error);
        toast.error('Failed to delete customer.');
      }
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 mt-20">
      <h2 className="text-3xl font-bold mb-6 text-center">Manage Customers</h2>

      {loading ? (
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin text-4xl text-blue-600" />
        </div>
      ) : customers.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border rounded-lg">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-2 border">Customer ID</th>
                <th className="px-4 py-2 border">Full Name</th>
                <th className="px-4 py-2 border">Email</th>
                <th className="px-4 py-2 border">Username</th>
                <th className="px-4 py-2 border">Company Name</th>
                <th className="px-4 py-2 border">Status</th>
                <th className="px-4 py-2 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer) => (
                <tr key={customer._id} className="hover:bg-gray-100 transition-colors">
                  <td className="px-4 py-2 border">{customer._id}</td>
                  <td className="px-4 py-2 border">{customer.fullName}</td>
                  <td className="px-4 py-2 border">{customer.email}</td>
                  <td className="px-4 py-2 border">{customer.username}</td>
                  <td className="px-4 py-2 border">{customer.companyName || '—'}</td>
                  <td className="px-4 py-2 border">
                    {customer.isApproved ? (
                      <span className="text-green-600 font-semibold">Approved</span>
                    ) : (
                      <span className="text-red-600 font-semibold">Not Approved</span>
                    )}
                  </td>
                  <td className="px-4 py-2 border">
                    <div className="flex space-x-2">
                      {customer.isApproved ? (
                        <button
                          onClick={() => handleApprove(customer._id, false)}
                          className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700 transition-colors flex items-center"
                          aria-label="Disapprove Customer"
                        >
                          <FaTimes className="mr-1" aria-hidden="true" /> Disapprove
                        </button>
                      ) : (
                        <button
                          onClick={() => handleApprove(customer._id, true)}
                          className="bg-green-600 text-white px-3 py-1 rounded hover:bg-green-700 transition-colors flex items-center"
                          aria-label="Approve Customer"
                        >
                          <FaCheck className="mr-1" aria-hidden="true" /> Approve
                        </button>
                      )}
                      <button
                        onClick={() => handleDelete(customer._id)}
                        className="bg-gray-600 text-white px-3 py-1 rounded hover:bg-gray-700 transition-colors flex items-center"
                        aria-label="Delete Customer"
                      >
                        <FaTrash className="mr-1" aria-hidden="true" /> Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center text-gray-600">No customers found.</p>
      )}
    </div>
  );
}

export default ManageCustomersPage;
