// src/pages/Admin/ManageCollectionsPage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FaEdit, FaTrash, FaSpinner } from 'react-icons/fa';

function ManageCollectionsPage() {
  const [collections, setCollections] = useState([]);
  const [name, setName] = useState('');
  const [editId, setEditId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');

  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchCollections();
    // eslint-disable-next-line
  }, []);

  const fetchCollections = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/collections`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCollections(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching collections:', error);
      setErrorMsg('Failed to load collections.');
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name.trim()) {
      toast.error('Collection name cannot be empty.');
      return;
    }

    try {
      if (editId) {
        // Update collection
        await axios.put(
          `${process.env.REACT_APP_API_URL}/api/collections/${editId}`,
          { name },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success('Collection updated.');
      } else {
        // Create new collection
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/collections`,
          { name },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success('Collection added.');
      }
      setName('');
      setEditId(null);
      fetchCollections();
    } catch (error) {
      console.error('Error saving collection:', error);
      toast.error(error.response?.data?.message || 'Failed to save collection.');
    }
  };

  const handleEdit = (collection) => {
    setName(collection.name);
    setEditId(collection._id);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this collection?')) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/collections/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success('Collection deleted.');
        fetchCollections();
      } catch (error) {
        console.error('Error deleting collection:', error);
        toast.error(
          error.response?.data?.message ||
            'Failed to delete collection. Ensure no spa models are linked to it.'
        );
      }
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 mt-20">
      <h2 className="text-3xl font-bold mb-6 text-center">Manage Collections</h2>

      {/* Error Message */}
      {errorMsg && (
        <div className="bg-red-100 text-red-700 p-4 mb-4 rounded">{errorMsg}</div>
      )}

      {/* Add/Edit Collection Form */}
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow mb-6">
        <div className="flex flex-col md:flex-row items-center">
          <div className="flex-1 mb-4 md:mb-0">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
              Collection Name:
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              placeholder="e.g., Premium Collection"
              required
            />
          </div>
          <div className="md:ml-4">
            <button
              type="submit"
              className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors flex items-center"
            >
              {loading ? <FaSpinner className="animate-spin mr-2" /> : null}
              {editId ? 'Update Collection' : 'Add Collection'}
            </button>
            {editId && (
              <button
                type="button"
                onClick={() => {
                  setName('');
                  setEditId(null);
                }}
                className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700 transition-colors ml-2 flex items-center"
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      </form>

      {/* Collections Table */}
      {loading ? (
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin text-4xl text-blue-600" aria-label="Loading Spinner" />
        </div>
      ) : collections.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border rounded-lg">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-2 border">Name</th>
                <th className="px-4 py-2 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {collections.map((collection) => (
                <tr key={collection._id} className="hover:bg-gray-100 transition-colors">
                  <td className="px-4 py-2 border">{collection.name}</td>
                  <td className="px-4 py-2 border">
                    <button
                      onClick={() => handleEdit(collection)}
                      className="bg-green-600 text-white px-3 py-1 rounded mr-2 hover:bg-green-700 transition-colors flex items-center"
                    >
                      <FaEdit className="mr-1" aria-hidden="true" /> Edit
                    </button>
                    <button
                      onClick={() => handleDelete(collection._id)}
                      className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700 transition-colors flex items-center"
                    >
                      <FaTrash className="mr-1" aria-hidden="true" /> Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center text-gray-600">No collections found. Please add some.</p>
      )}
    </div>
  );
}

export default ManageCollectionsPage;
