// src/pages/ReservationConfirmationPage.js

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';

function ReservationConfirmationPage() {
  const location = useLocation();
  const { referenceText } = location.state || {};

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow text-center">
        <FaCheckCircle className="mx-auto h-12 w-12 text-green-600 mb-4" />
        <h2 className="text-2xl font-bold mb-4">Reservation Confirmed</h2>
        {referenceText && (
          <p className="text-gray-700 mb-4">
            <strong>Reservation Reference:</strong> {referenceText}
          </p>
        )}
        <p className="text-gray-700 mb-6">
          Thank you for your reservation! Our team will review your requests and get back to
          you shortly.
        </p>
        <p className="text-gray-700 mb-6">
          You will receive email notifications once your reservations have been approved or
          rejected.
        </p>
        <Link
          to="/"
          className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors"
        >
          Return to Home
        </Link>
      </div>
    </div>
  );
}

export default ReservationConfirmationPage;
