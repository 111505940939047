// src/components/SpaModelCard.js

import React from 'react';
import { Link } from 'react-router-dom';
import { FaStar, FaRegStar } from 'react-icons/fa';

function SpaModelCard({ model }) {
  // Example: Assuming model.rating exists (0 to 5)
  const renderStars = () => {
    const stars = [];
    const rating = model.rating || 0;
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(<FaStar key={i} className="text-yellow-400 inline" aria-hidden="true" />);
      } else {
        stars.push(<FaRegStar key={i} className="text-yellow-400 inline" aria-hidden="true" />);
      }
    }
    return stars;
  };

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300">
      {/* Image Section */}
      <div className="relative">
        {model.images && model.images.length > 0 ? (
          <img
            src={`${process.env.REACT_APP_API_URL}${model.images[0]}`}
            alt={model.name}
            className="w-full h-60 object-contain bg-gray-100 p-2"
          />
        ) : (
          <div className="w-full h-60 bg-gray-200 flex items-center justify-center rounded">
            <span className="text-gray-500 text-sm">No Image Available</span>
          </div>
        )}
        {/* Optional: Overlay Label */}
        {model.isFeatured && (
          <span className="absolute top-2 left-2 bg-red-500 text-white text-xs px-2 py-1 rounded">
            Featured
          </span>
        )}
      </div>

      {/* Content Section */}
      <div className="p-4">
        <h3 className="text-xl font-semibold mb-2">{model.name}</h3>
        <p className="text-gray-600 mb-2">
          <strong>Collection:</strong> {model.collectionId.name}
        </p>
        <p className="text-gray-600 mb-2">
          <strong>Price:</strong> €{model.price.toLocaleString()}
        </p>
        {/* Optional: Rating */}
        {model.rating && (
          <div className="mb-2">
            {renderStars()} <span className="text-sm text-gray-600">({model.rating})</span>
          </div>
        )}
        {/* Optional: Short Description */}
        {model.shortDescription && (
          <p className="text-gray-700 mb-4">{model.shortDescription}</p>
        )}
        <Link
          to={`/spa-models/${model._id}`}
          className="inline-block bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
          aria-label={`View details of ${model.name}`}
        >
          View Details
        </Link>
      </div>
    </div>
  );
}

export default SpaModelCard;
