// src/pages/LoginPage.js

import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FaUserShield, FaUser } from 'react-icons/fa';

function LoginPage() {
  const [isCustomerLogin, setIsCustomerLogin] = useState(true);
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleToggle = () => {
    setIsCustomerLogin(!isCustomerLogin);
    setEmailOrUsername('');
    setPassword('');
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!emailOrUsername || !password) {
      toast.error('Please fill in all fields.');
      return;
    }

    try {
      let response;

      if (isCustomerLogin) {
        // Customer login
        response = await axios.post(`${process.env.REACT_APP_API_URL}/api/customer/login`, {
          email: emailOrUsername,
          password,
        });

        localStorage.setItem('customerToken', response.data.token);
      } else {
        // Admin login
        response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/login`, {
          username: emailOrUsername,
          password,
        });
        localStorage.setItem('token', response.data.token);
      }

      toast.success('Login successful!');
      window.location.href = '/';
    } catch (error) {
      console.error('Login error:', error);
      toast.error(error.response?.data?.message || 'Login failed.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="flex flex-col items-center">
          {isCustomerLogin ? (
            <FaUser className="h-12 w-12 text-blue-600 mb-4" />
          ) : (
            <FaUserShield className="h-12 w-12 text-green-600 mb-4" />
          )}
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {isCustomerLogin ? 'Customer Login' : 'Admin Login'}
          </h2>
        </div>
        <form className="mt-8 space-y-6 bg-white p-8 rounded-lg shadow" onSubmit={handleLogin}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div className="mb-4">
              <label htmlFor="emailOrUsername" className="block text-sm font-medium text-gray-700 mb-1">
                {isCustomerLogin ? 'Email' : 'Username'}
              </label>
              <input
                id="emailOrUsername"
                name="emailOrUsername"
                type={isCustomerLogin ? 'email' : 'text'}
                autoComplete={isCustomerLogin ? 'email' : 'username'}
                required
                value={emailOrUsername}
                onChange={(e) => setEmailOrUsername(e.target.value)}
                className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder={isCustomerLogin ? 'Email address' : 'Username'}
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Password"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
            >
              {isCustomerLogin ? 'Login as Customer' : 'Login as Admin'}
            </button>
          </div>
        </form>

        <div className="flex justify-center">
          <button
            onClick={handleToggle}
            className="text-sm text-blue-600 hover:text-blue-800 underline focus:outline-none"
          >
            {isCustomerLogin ? 'Switch to Admin Login' : 'Switch to Customer Login'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
