// src/pages/Admin/AdminDashboardPage.js

import React from 'react';
import { Link } from 'react-router-dom';
import { FaBoxes, FaCogs, FaSpa, FaClipboardList, FaUsers } from 'react-icons/fa';

function AdminDashboardPage() {
  const adminOptions = [
    {
      title: 'Manage Customers',
      description: 'View, approve, or delete customers.',
      icon: <FaUsers className="h-12 w-12 text-indigo-600" />,
      link: '/admin/manage-customers',
    },
    // ... existing options
    {
      title: 'Manage Collections',
      description: 'View, add, edit, or delete spa collections.',
      icon: <FaBoxes className="h-12 w-12 text-blue-600" />,
      link: '/admin/manage-collections',
    },
    {
      title: 'Manage Spa Models',
      description: 'View, add, edit, or delete spa models.',
      icon: <FaSpa className="h-12 w-12 text-green-600" />,
      link: '/admin/manage-spa-models',
    },
    {
      title: 'Manage Stock',
      description: 'View, add, edit, or delete spa units (stock).',
      icon: <FaCogs className="h-12 w-12 text-yellow-600" />,
      link: '/admin/manage-stock',
    },
    {
      title: 'Manage Reservations',
      description: 'View and update customer reservations.',
      icon: <FaClipboardList className="h-12 w-12 text-purple-600" />,
      link: '/admin/manage-reservations',
    },
  ];

  return (
    <div className="container mx-auto px-4 py-8 mt-20">
      <h2 className="text-3xl font-bold mb-6 text-center">Admin Dashboard</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {adminOptions.map((option, index) => (
          <Link
            to={option.link}
            key={index}
            className="bg-white p-6 rounded-lg shadow hover:bg-gray-100 transition-colors flex flex-col items-center text-center"
          >
            {option.icon}
            <h3 className="text-xl font-semibold mt-4">{option.title}</h3>
            <p className="text-gray-600 mt-2">{option.description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default AdminDashboardPage;
