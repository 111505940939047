// src/App.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import SpaModelsPage from './pages/SpaModelsPage';
import ModelDetailsPage from './pages/ModelDetailsPage';
import AvailableStockPage from './pages/AvailableStockPage';
import AboutUsPage from './pages/AboutUsPage';
import ContactUsPage from './pages/ContactUsPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ReservationConfirmationPage from './pages/ReservationConfirmationPage'; // Import the page
import AdminDashboardPage from './pages/Admin/AdminDashboardPage';
import ManageCollectionsPage from './pages/Admin/ManageCollectionsPage';
import ManageSpaModelsPage from './pages/Admin/ManageSpaModelsPage';
import ManageStockPage from './pages/Admin/ManageStockPage';
import ManageReservationsPage from './pages/Admin/ManageReservationsPage';
import ManageCustomersPage from './pages/Admin/ManageCustomersPage';
import MyReservationsPage from './pages/MyReservationsPage';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <div>
      <Navbar />
      <main className="mt-16">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/spa-models" element={<SpaModelsPage />} />
          <Route path="/spa-models/:id" element={<ModelDetailsPage />} />
          {/* Protect the Available Stock page */}
          <Route
            path="/available-stock"
            element={
              <ProtectedRoute adminOnly={false}>
                <AvailableStockPage />
              </ProtectedRoute>
            }
          />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          {/* Reservation Confirmation Page */}
          <Route
            path="/reservation-confirmation"
            element={
              <ProtectedRoute adminOnly={false}>
                <ReservationConfirmationPage />
              </ProtectedRoute>
            }
          />
          {/* My Reservations Route */}
          <Route
            path="/my-reservations"
            element={
              <ProtectedRoute adminOnly={false}>
                <MyReservationsPage />
              </ProtectedRoute>
            }
          />
          {/* Admin Routes */}
          <Route
            path="/admin"
            element={
              <ProtectedRoute adminOnly={true}>
                <AdminDashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/manage-customers"
            element={
              <ProtectedRoute adminOnly={true}>
                <ManageCustomersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/manage-collections"
            element={
              <ProtectedRoute adminOnly={true}>
                <ManageCollectionsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/manage-spa-models"
            element={
              <ProtectedRoute adminOnly={true}>
                <ManageSpaModelsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/manage-stock"
            element={
              <ProtectedRoute adminOnly={true}>
                <ManageStockPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/manage-reservations"
            element={
              <ProtectedRoute adminOnly={true}>
                <ManageReservationsPage />
              </ProtectedRoute>
            }
          />
          {/* 404 Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

// Optional: Create a NotFound component for unmatched routes
function NotFound() {
  return (
    <div className="container mx-auto p-8 mt-20 text-center">
      <h2 className="text-3xl font-bold mb-4">404 - Page Not Found</h2>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
}

export default App;
