// src/pages/SpaModelsPage.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SpaModelCard from '../components/SpaModelCard';
import { FaSearch } from 'react-icons/fa';
import { FaSpinner } from 'react-icons/fa';

function SpaModelsPage() {
  const [spaModels, setSpaModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchSpaModels = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/spa-models`);
        setSpaModels(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching spa models:', err);
        setError(true);
        setLoading(false);
      }
    };

    const fetchCollections = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/collections`);
        setCollections(response.data);
      } catch (err) {
        console.error('Error fetching collections:', err);
      }
    };

    fetchSpaModels();
    fetchCollections();
    // eslint-disable-next-line
  }, []);

  const filteredSpaModels = spaModels.filter((model) => {
    const matchesCollection = selectedCollection
      ? model.collectionId._id === selectedCollection
      : true;
    const matchesSearch = model.name.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesCollection && matchesSearch;
  });

  return (
    <div className="container mx-auto px-4 py-8 mt-20">
      <h1 className="text-4xl font-bold text-center mb-8">Our Spa Models</h1>

      {/* Filters */}
      <div className="flex flex-col md:flex-row items-center justify-between mb-8 space-y-4 md:space-y-0">
        {/* Collection Filter */}
        <div className="flex items-center space-x-2 w-full md:w-auto">
          <FaSearch className="text-gray-500" aria-hidden="true" />
          <label htmlFor="collection" className="block text-sm font-medium text-gray-700 sr-only">
            Filter by Collection
          </label>
          <select
            id="collection"
            value={selectedCollection}
            onChange={(e) => setSelectedCollection(e.target.value)}
            className="mt-1 block w-full md:w-64 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            aria-label="Filter by Collection"
          >
            <option value="">All Collections</option>
            {collections.map((collection) => (
              <option key={collection._id} value={collection._id}>
                {collection.name}
              </option>
            ))}
          </select>
        </div>

        {/* Search */}
        <div className="flex items-center space-x-2 w-full md:w-1/3">
          <FaSearch className="text-gray-500" aria-hidden="true" />
          <label htmlFor="search" className="block text-sm font-medium text-gray-700 sr-only">
            Search by Name
          </label>
          <input
            type="text"
            id="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mt-1 block w-full pl-3 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Search spa model name"
            aria-label="Search Spa Models"
          />
        </div>
      </div>

      {/* Spa Models Grid */}
      {loading ? (
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin text-4xl text-blue-600" aria-label="Loading Spinner" />
        </div>
      ) : error ? (
        <p className="text-center text-red-600">Failed to load spa models.</p>
      ) : filteredSpaModels.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredSpaModels.map((model) => (
            <SpaModelCard key={model._id} model={model} />
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-600">No spa models match your criteria.</p>
      )}
    </div>
  );
}

export default SpaModelsPage;
