// src/pages/Admin/ManageReservationsPage.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FaSpinner, FaCheck, FaTimes, FaTrash } from 'react-icons/fa';

function ManageReservationsPage() {
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [loadingReservations, setLoadingReservations] = useState({});

  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchReservations();
    // eslint-disable-next-line
  }, []);

  const fetchReservations = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/reservations`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setReservations(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching reservations:', error);
      setErrorMsg('Failed to load reservations.');
      setLoading(false);
    }
  };

  const handleUpdateStatus = async (id, status) => {
    try {
      setLoadingReservations((prevState) => ({ ...prevState, [id]: true }));
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/reservations/${id}`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success('Reservation status updated.');
      fetchReservations();
    } catch (error) {
      console.error('Error updating reservation status:', error);
      toast.error(error.response?.data?.message || 'Failed to update reservation status.');
    } finally {
      setLoadingReservations((prevState) => ({ ...prevState, [id]: false }));
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this reservation?')) {
      try {
        setLoadingReservations((prevState) => ({ ...prevState, [id]: true }));
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/reservations/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success('Reservation deleted.');
        fetchReservations();
      } catch (error) {
        console.error('Error deleting reservation:', error);
        toast.error(error.response?.data?.message || 'Failed to delete reservation.');
      } finally {
        setLoadingReservations((prevState) => ({ ...prevState, [id]: false }));
      }
    }
  };

  const getStatusStyles = (status) => {
    switch (status.toLowerCase()) {
      case 'approved':
        return 'text-green-600 flex items-center';
      case 'rejected':
        return 'text-red-600 flex items-center';
      case 'pending':
        return 'text-yellow-600 flex items-center';
      default:
        return 'text-gray-600 flex items-center';
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 mt-20">
      <h2 className="text-3xl font-bold mb-6 text-center">Manage Reservations</h2>

      {/* Error Message */}
      {errorMsg && (
        <div className="bg-red-100 text-red-700 p-4 mb-4 rounded text-center">
          {errorMsg}
        </div>
      )}

      {/* Reservations Table */}
      {loading ? (
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin text-4xl text-blue-600" aria-label="Loading Spinner" />
        </div>
      ) : reservations.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border rounded-lg">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-2 border">Reservation ID</th>
                <th className="px-4 py-2 border">Customer</th>
                <th className="px-4 py-2 border">Reference</th>
                <th className="px-4 py-2 border">Date</th>
                <th className="px-4 py-2 border">Units Reserved</th>
                <th className="px-4 py-2 border">Status</th>
                <th className="px-4 py-2 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {reservations.map((reservation) => (
                <tr
                  key={reservation._id}
                  className={`${
                    reservation.status === 'Approved'
                      ? 'bg-green-50'
                      : reservation.status === 'Rejected'
                      ? 'bg-red-50'
                      : 'bg-yellow-50'
                  } hover:bg-gray-100 transition-colors`}
                >
                  <td className="px-4 py-2 border text-center">{reservation._id}</td>
                  <td className="px-4 py-2 border">
                    {reservation.customerId ? (
                      <>
                        {reservation.customerId.fullName} ({reservation.customerId.email})
                      </>
                    ) : (
                      'Customer Deleted'
                    )}
                  </td>
                  <td className="px-4 py-2 border">
                    {reservation.referenceText || '—'}
                  </td>
                  <td className="px-4 py-2 border">
                    {new Date(reservation.createdAt).toLocaleString()}
                  </td>
                  <td className="px-4 py-2 border">
                    <ul className="list-disc pl-5">
                      {reservation.spaUnitIds.map((unit) => (
                        <li key={unit._id}>
                          {unit.spaModelId.name} - {unit.color} (€{unit.spaModelId.price.toLocaleString()})
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td className={`px-4 py-2 border ${getStatusStyles(reservation.status)}`}>
                    {reservation.status.charAt(0).toUpperCase() + reservation.status.slice(1)}
                  </td>
                  <td className="px-4 py-2 border">
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleUpdateStatus(reservation._id, 'Approved')}
                        disabled={loadingReservations[reservation._id]}
                        className="bg-green-600 text-white px-3 py-1 rounded hover:bg-green-700 transition-colors flex items-center disabled:opacity-50"
                        aria-label="Approve Reservation"
                      >
                        {loadingReservations[reservation._id] ? (
                          <>
                            <FaSpinner className="animate-spin mr-1" /> Approving...
                          </>
                        ) : (
                          <>
                            <FaCheck className="mr-1" aria-hidden="true" /> Approve
                          </>
                        )}
                      </button>
                      <button
                        onClick={() => handleUpdateStatus(reservation._id, 'Rejected')}
                        disabled={loadingReservations[reservation._id]}
                        className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700 transition-colors flex items-center disabled:opacity-50"
                        aria-label="Reject Reservation"
                      >
                        {loadingReservations[reservation._id] ? (
                          <>
                            <FaSpinner className="animate-spin mr-1" /> Rejecting...
                          </>
                        ) : (
                          <>
                            <FaTimes className="mr-1" aria-hidden="true" /> Reject
                          </>
                        )}
                      </button>
                      <button
                        onClick={() => handleDelete(reservation._id)}
                        disabled={loadingReservations[reservation._id]}
                        className="bg-gray-600 text-white px-3 py-1 rounded hover:bg-gray-700 transition-colors flex items-center disabled:opacity-50"
                        aria-label="Delete Reservation"
                      >
                        {loadingReservations[reservation._id] ? (
                          <>
                            <FaSpinner className="animate-spin mr-1" /> Deleting...
                          </>
                        ) : (
                          <>
                            <FaTrash className="mr-1" aria-hidden="true" /> Delete
                          </>
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center text-gray-600">No reservations found.</p>
      )}
    </div>
  );
}

export default ManageReservationsPage;
