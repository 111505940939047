// src/pages/ContactUsPage.js

import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FaPaperPlane, FaSpinner } from 'react-icons/fa';

function ContactUsPage() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    subject: '',
    message: '',
  });

  const [loading, setLoading] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic form validation
    if (!formData.fullName || !formData.email || !formData.subject || !formData.message) {
      toast.error('Please fill in all required fields.');
      return;
    }

    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      toast.error('Please enter a valid email address.');
      return;
    }

    setLoading(true);

    try {
      // Send POST request to backend
      await axios.post(`${process.env.REACT_APP_API_URL}/api/contact`, formData);

      // Success feedback
      toast.success('Your message has been sent successfully!');

      // Reset form
      setFormData({
        fullName: '',
        email: '',
        subject: '',
        message: '',
      });
    } catch (error) {
      console.error('Error submitting contact form:', error);
      toast.error(
        error.response?.data?.message ||
          'Failed to send your message. Please try again later.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-16 mt-20">
      <h1 className="text-4xl font-bold mb-8 text-center">Contact Us</h1>
      
      <div className="flex flex-col lg:flex-row items-start lg:space-x-12">
        {/* Contact Form */}
        <div className="lg:w-1/2 w-full">
          <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-md">
            <div className="mb-4">
              <label htmlFor="fullName" className="block text-sm font-semibold text-gray-700 mb-1">
                Full Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="John Doe"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-semibold text-gray-700 mb-1">
                Email<span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="john.doe@example.com"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="subject" className="block text-sm font-semibold text-gray-700 mb-1">
                Subject<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your Subject"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="message" className="block text-sm font-semibold text-gray-700 mb-1">
                Message<span className="text-red-500">*</span>
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded p-2 h-32 resize-none focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your message..."
                required
              ></textarea>
            </div>

            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors flex items-center"
                disabled={loading}
              >
                {loading ? <FaSpinner className="animate-spin mr-2" /> : <FaPaperPlane className="mr-2" />}
                {loading ? 'Sending...' : 'Send Message'}
              </button>
            </div>
          </form>
        </div>

        {/* Contact Information */}
        <div className="lg:w-1/2 w-full mt-8 lg:mt-0">
          <h2 className="text-2xl font-semibold mb-4">Get in Touch</h2>
          <p className="text-gray-700 mb-4">
            Whether you have questions about our spa models, need assistance with your reservation, or just want to share your feedback, we're here to help!
          </p>
          <ul className="space-y-2">
            <li className="flex items-center">
              <span className="font-semibold">Email:</span>
              <a href="mailto:contact@rocaspa.fr" className="ml-2 hover:text-blue-500">
                contact@rocaspa.fr
              </a>
            </li>
            <li className="flex items-center">
              <span className="font-semibold">Phone:</span>
              <a href="tel:+33652016373" className="ml-2 hover:text-blue-500">
               +336 52 01 63 73
              </a>
            </li>
            <li className="flex items-center">
              <span className="font-semibold">Head office : </span>
              <span className="ml-2">1527 Avenue de petite synthe 59640 Dunkerque</span>
            </li>
            <li className="flex items-center">
              <span className="font-semibold">Main Warehouse of stock :</span>
              <span className="ml-2">15 rue du guindal bâtiment 1.16 and 1.17 59630 Bourbourg</span>
            </li>
            <li className="flex items-center">
              <span className="font-semibold">2nd Warehouse stock : </span>
              <span className="ml-2">3 rue des caroubiers 66600 Rivesaltes</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ContactUsPage;
